import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagramSquare, faTwitter } from '@fortawesome/free-brands-svg-icons'

import SEO from '../components/seo';

import styled from '@emotion/styled'
import { oceanBlue, skyBlue, copper } from '../constants/theme';

// import Instafeed from '../components/Instafeed';

import socialHeaderImage from '../images/social-header.jpg';
import { BasicPageContain, CopyBlockStyle } from '../utils/copperTypography';


export const ButtonWrapper = styled.button`

  &.inactive {
    cursor: none;
    &:hover {
      background-color: ${skyBlue};
      cursor: initial;
    }
  }
  margin-top: 0;
  border: 1px solid white;
  border-radius: 3px;
  background-color: ${skyBlue};
  color: white;
  padding: 14px 16px;
  font-size: 14px;
  &:hover {
    background-color: ${oceanBlue};
    cursor: pointer;
  }
`

export const SocialIcons = styled.div`

  margin-top: 0;
  margin-bottom: 40px;
  border: 1px solid white;
  a {
    padding: 14px 16px;
    font-size: 32px;
    color: ${copper};
  }
  a:hover {
    color: ${oceanBlue};
  }
  font-size: 32px;
`


const SalesPage = () => {


  return (
    <BasicPageContain>
        <SEO
          title="Social Media"
          description="Follow us on social media for the most advance notice of upcoming sales, new releases, and other updates from Copper Compression."
        />
        <img src={socialHeaderImage} style={{width:'100%'}} alt="Thank You Copper Compression" />
        <CopyBlockStyle className='sale-page'>
          <h3>Copper Compression</h3>
          <h1>Social Media</h1>
          <p className='sales-text'>Follow us on social media for the most advance notice of upcoming sales, new releases, and other updates from Copper Compression.</p>
          <SocialIcons>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <a href="https://facebook.com/coppercompression"><FontAwesomeIcon icon={faFacebook} /></a>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <a href="https://instagram.com/coppercompression"><FontAwesomeIcon icon={faInstagramSquare} /></a>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <a href="https://twitter.com/coppercomp"><FontAwesomeIcon icon={faTwitter} /></a>
          </SocialIcons>
          <p className='sales-text'>The latest from our <a href="https://instagram.com/coppercompression">@coppercompression</a> feed</p>
          {/*<Instafeed />*/}
        </CopyBlockStyle>
      </BasicPageContain>
  )
};

export default SalesPage;
